
// export default Layout
import React from "react";
import { css, Styled } from "theme-ui";
import Header from "gatsby-theme-blog/src/components/header";
import "./layout.scss";
// import Colors from "gatsby-plugins-theme-ui/src/components/colors";

export default ({ children, ...props }) => (
  <Styled.root css={css({ minHeight:'100vh'})}>
    <Header {...props} />
    <div>
      <div
        css={css({
          maxWidth: `container`,
          mx: `auto`,
          px: 3,
          py: 4
        })}
      >
        {children}
      </div>
    </div>
  </Styled.root>
);
